@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.history-tl-container {
  display: block;
  position: relative;
}
.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;
}
.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 200px;
  min-height: 50px;
  /*background: rgba(255,255,0,0.1);*/
  border-left: 1px dashed #86d6ff;
  padding: 0 0 50px 30px;
  position: relative;
}
.history-tl-container ul.tl li:last-child {
  border-left: 0;
}
.history-tl-container ul.tl li::before {
  position: absolute;
  left: -18px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #258cc7;
  height: 35px;
  width: 35px;
  transition: all 500ms ease-in-out;
}
.history-tl-container ul.tl li:hover::before {
  border-color: #258cc7;
  transition: all 1000ms ease-in-out;
}
ul.tl li .item-title {
}
ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
}
ul.tl li .timestamp {
  color: #4e4e4e;
  position: absolute;
  width: 100px;
  left: -150px;
  top: 5px;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
}

.empPassPhoto {
  object-fit: cover;
}

button:focus {
  outline: none;
}
.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 20px;
  width: auto;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menu li div {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

/* .promoteButton:hover {
  background-color: white;
  color: black;
} */
